

import React, { useMemo} from "react";
import {
  Flex,
  Box,
  Button,
  Select,
} from "@chakra-ui/react";
export default function Default({ pageIndex, pageCount, gotoPage, previousPage, nextPage, canPreviousPage, canNextPage }) {
  const pageNumbers = useMemo(() => [...Array(pageCount).keys()], [pageCount]);
  
  return (
    <Flex justify="space-between" align="center" mt="24px">
      <Box>
        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </Button>{" "}
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </Button>{" "}
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </Button>{" "}
        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </Button>{" "}
      </Box>
      <Box>
        <Select
          value={pageIndex}
          onChange={(e) => {
            const page = e.target.value;
            gotoPage(page);
          }}
        >
          {pageNumbers.map((pageNumber) => (
            <option key={pageNumber} value={pageNumber}>
              {pageNumber + 1}
            </option>
          ))}
        </Select>
      </Box>
    </Flex>
  );
}
